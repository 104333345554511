import React, { useState } from "react"
import { Link, Redirect } from "react-router-dom"

export default function CalculateButton({
  userToken,
  developers,
  specialists,
  selectedTimeline,
  selectedIndustry,
  selectedCompanySize,
  pastExperience,
  options,
  skills,
  managementPractices,
  validateSectionSix,
}) {
  const [redirect, setRedirect] = useState(false)

  // Developer variables to store in the database
  let frontendCount = 0
  let backendCount = 0
  let mobileappCount = 0
  let databaseCount = 0

  // Specialist variables to store in database
  let projectManagerCount = 0
  let uiuxDesignerCount = 0
  let businessAnalystCount = 0
  let devopsSpecialistCount = 0
  let qaEngineerCount = 0
  let remoteMaintenanceCount = 0

  // Options
  let projectType
  let needToHire
  let engagementModel
  pastExperience = pastExperience ? "y" : "n"

  function getSelectedDeveloperData() {
    const selectedDevelopers = developers.filter(
      (developer) => developer.checked
    )

    selectedDevelopers.forEach((developer) => {
      switch (developer.alias) {
        case "frontend":
          frontendCount = developer.count
          break
        case "backend":
          backendCount = developer.count
          break
        case "mobile":
          mobileappCount = developer.count
          break
        case "database":
          databaseCount = developer.count
          break
        default:
          break
      }
    })
  }

  function getSelectedSpecialistData() {
    const selectedSpecialists = specialists.filter(
      (specialist) => specialist.checked
    )

    selectedSpecialists.forEach((specialist) => {
      switch (specialist.alias) {
        case "project-manager":
          projectManagerCount = specialist.count
          break
        case "uiux-designer":
          uiuxDesignerCount = specialist.count
          break
        case "business-analyst":
          businessAnalystCount = specialist.count
          break
        case "devops-specialist":
          devopsSpecialistCount = specialist.count
          break
        case "qa-engineer":
          qaEngineerCount = specialist.count
          break
        case "remote-maintenance":
          remoteMaintenanceCount = specialist.count
          break
        default:
          break
      }
    })
  }

  function getSelectedProjectType() {
    projectType = options["projectTypes"].find(
      (projectType) => projectType.checked
    )
    projectType = projectType ? projectType.title : null
  }

  function getSelectedNeedToHire() {
    needToHire = options["startWorkingInDays"].find((item) => item.checked)
    needToHire = needToHire ? needToHire.title : null
  }

  function getSelectedEngagementModel() {
    engagementModel = options["engagementModels"].find(
      (engagementModel) => engagementModel.checked
    )
    engagementModel = engagementModel ? engagementModel.title : null
  }

  function getSelectedSkillsText(developer_type, skills) {
    // Filter skills array for selected developer type
    let skillsArray = skills.filter((skill) => skill.type === developer_type)

    if (skillsArray) {
      skillsArray = skillsArray.filter((skill) => skill.selected)
    }

    // empty string to make the skills text
    let skillSetString = ""

    // Build the skill set text
    if (skillsArray) {
      skillsArray.forEach((skill) => {
        skillSetString += `${skill.title}, `
      })
    }

    return skillSetString
  }

  function getSelectedManagementPractices() {
    // Filter skills array for selected developer type
    let practicesArray = managementPractices.filter(
      (practice) => practice.selected
    )

    // empty string to make the skills text
    let practicesString = ""

    // Build the skill set text
    if (practicesArray) {
      practicesArray.forEach((practice) => {
        practicesString += `${practice.title}, `
      })
    }

    return practicesString
  }

  async function fetchRequestUrl(requestUrl, requestOptions) {
    return await fetch(requestUrl, requestOptions)
      .then((response) => response)
      .then((response) => {
        console.log("Success: ", response)
        return response.ok
      })
      .catch((error) => {
        console.error("Error:", error)
      })
  }

  async function postData() {
    getSelectedDeveloperData()
    getSelectedSpecialistData()
    getSelectedProjectType()
    getSelectedNeedToHire()
    getSelectedEngagementModel()

    const inquiriesRequestOptions = {
      method: "POST",
      headers: {
        'Host': 'maytechweb.com.au',
        'Content-Type': 'application/json',
        'Content-Length': 473
      },
      body: JSON.stringify({
        uuid: userToken,
        // Option data
        industry: selectedIndustry || null,
        timeline: options["timelineNegative"][0].checked
          ? options["timelineNegative"][0].title
          : selectedTimeline.title,
        company_size: selectedCompanySize,
        project_type: projectType,
        need_to_hire: needToHire,
        past_experience: pastExperience,
        engagement_model: engagementModel,
        // Management practices
        management_practices: getSelectedManagementPractices(),
      }),
    }
    console.log(userToken);
    const rolesRequestOptions = {
      method: "POST",
      headers: {
        'Host': 'maytechweb.com.au',
        'Content-Type': 'application/json',
        'Content-Length': 473
      },
      body: JSON.stringify({
        uuid: userToken,
        // Developer data
        frontend_developers: frontendCount,
        backend_developers: backendCount,
        mobileapp_developers: mobileappCount,
        database_developers: databaseCount,
        // Specialist data
        project_managers: projectManagerCount,
        uiux_designers: uiuxDesignerCount,
        business_analysts: businessAnalystCount,
        devops_specialists: devopsSpecialistCount,
        qa_engineers: qaEngineerCount,
        remote_maintenances: remoteMaintenanceCount,
        // Skills
        frontend_skills: getSelectedSkillsText("frontend", skills),
        backend_skills: getSelectedSkillsText("backend", skills),
        mobile_skills: getSelectedSkillsText("mobile", skills),
        database_skills: getSelectedSkillsText("database", skills),
      }),
    }

    // fetch create inquiries
    const response1 = await fetchRequestUrl(
      "https://maytechweb.com.au/inquiries",
      inquiriesRequestOptions
    )

    // fetch create roles
    const response2 = await fetchRequestUrl(
      "https://maytechweb.com.au/roles",
      rolesRequestOptions
    )

    if (response1 && response2) {
      setRedirect(true)
    }
  }

  function handleCalculateClick() {
    if (validateSectionSix()) {
      postData()
    } else {
      alert("Please select the fields required")
    }
  }

  if (redirect) {
    return <Redirect to="/section7" />
  }

  return (
    <>
      <Link>
        <button className="btn btn-next" onClick={handleCalculateClick}>
          Calculate
        </button>
      </Link>
    </>
  )
}
