import React from "react"
import { Link, useLocation } from "react-router-dom"

// Import card components
import RoleCard from "./cards/RoleCard"
import SkillCard from "./cards/SkillCard"
import DetailCard from "./cards/DetailCard"

// Import fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

function ProgressDisplay({
  developers,
  skills,
  specialists,
  selectedIndustry,
  options,
  managementPractices,
  selectedTimeline,
  selectedCompanySize,
  pastExperience,
  showPastExperienceCard,
}) {
  // Empty array to store developer card elements
  const developerCardElements = []

  // Empty array to store specialist card elements
  const specialistCardElements = []

  // Empty array to store (active) skill card elements
  const selectedSkillCardElements = []

  // Build developer card element group
  developers.map((developer, index) =>
    developerCardElements.push(
      <RoleCard
        key={index}
        roleCount={developer.count}
        roleAlias={developer.alias}
      />
    )
  )

  // Build specialist card element group
  specialists.forEach((specialist, index) => {
    if (specialist.count !== null) {
      specialistCardElements.push(
        <RoleCard
          key={index}
          roleCount={specialist.count}
          roleAlias={specialist.alias}
        />
      )
    }
  })

  const selectedSkills = skills.filter((skill) => skill.selected)

  // Build skill card element group
  for (let i = 0; i < 16; i++) {
    let skill = selectedSkills[i]

    if (skill) {
      selectedSkillCardElements.push(
        <SkillCard key={skill.alias} skillAlias={skill.alias} />
      )
    } else {
      selectedSkillCardElements.push(<SkillCard key={i} />)
    }
  }

  // Get the selected start working in days -> title
  let selectedStartWorkingInDays = options["startWorkingInDays"].find(
    (option) => option.checked
  )
  selectedStartWorkingInDays =
    selectedStartWorkingInDays && selectedStartWorkingInDays.title

  // Get the selected engagement model -> title
  let selectedEngagementModel = options["engagementModels"].find(
    (option) => option.checked
  )
  selectedEngagementModel =
    selectedEngagementModel && selectedEngagementModel.title

  // Get the selected management practices
  const selectedManagementPractices = managementPractices.filter(
    (practice) => practice.selected
  )

  // Build selected management practices element group
  const selectedManagementPracticesElements = []

  for (let i = 0; i < 4; i++) {
    if (selectedManagementPractices && selectedManagementPractices[i]) {
      selectedManagementPracticesElements.push(
        <div className="col-6 col-md-3 py-2 px-1" key={i}>
          <DetailCard
            cardContent={selectedManagementPractices[i].title}
            iconName="faPlus"
          />
        </div>
      )
    } else {
      selectedManagementPracticesElements.push(
        <div className="col-6 col-md-3 py-2 px-1" key={i}>
          <DetailCard />
        </div>
      )
    }
  }

  return (
    <div>
      <div className="text-right my-2">
        {useLocation().pathname === "/section7" && (
          <Link to="/">
            <button className="btn btn-edit">
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
              Edit
            </button>
          </Link>
        )}
      </div>

      <div className="px-4">
        <h2
          className="text-uppercase text-white mb-3 h5"
          style={{ marginTop: "36px" }}
        >
          Project Team
        </h2>

        <div className="mb-3">
          <div className="text-orange mb-1">Developers</div>
          <div className="role-cards-wrapper">{developerCardElements}</div>
        </div>

        <div className="mb-3">
          <div className="text-orange mb-1">Other</div>
          <div className="role-cards-wrapper">{specialistCardElements}</div>
        </div>

        <div className="mb-3">
          <div className="text-orange mb-1">Skills</div>
          <div className="skill-cards-wrapper">{selectedSkillCardElements}</div>
        </div>

        <div>
          <div className="detail-cards-wrapper mb-2">
            <div className="row px-3">
              <div className="single-card-wrapper col-6 col-md-4 px-1 mb-2">
                <div className="text-orange mb-1 text-center">Industry</div>
                <DetailCard cardContent={selectedIndustry} />
              </div>
              <div className="single-card-wrapper col-6 col-md-4 px-1 mb-2">
                <div className="text-orange mb-1 text-center">
                  Project Timeline
                </div>
                <DetailCard cardContent={selectedTimeline.title} />
              </div>
              <div className="single-card-wrapper col-6 col-md-4 px-1 mb-2">
                <div className="text-orange mb-1 text-center">Need to hire</div>
                <DetailCard cardContent={selectedStartWorkingInDays} />
              </div>
              <div className="single-card-wrapper col-6 col-md-4 px-1 mb-2">
                <div className="text-orange mb-1 text-center">Company size</div>
                <DetailCard cardContent={selectedCompanySize} />
              </div>
              <div className="single-card-wrapper col-6 col-md-4 px-1 mb-2">
                <div className="text-orange mb-1 text-center">
                  Past experience
                </div>
                <DetailCard
                  cardContent={
                    showPastExperienceCard && (pastExperience ? "Yes" : "No")
                  }
                />
              </div>
              <div className="single-card-wrapper col-6 col-md-4 px-1 mb-2">
                <div className="text-orange mb-1 text-center">
                  Engagement model
                </div>
                <DetailCard cardContent={selectedEngagementModel} />
              </div>
            </div>
          </div>
        </div>

        <div className="mb-3">
          <div className="container px-md-0 px-lg-3">
            <div className="row">
              <div className="text-orange mb-1">
                Preferred project management
              </div>
            </div>
            <div className="row practices-cards-wrapper">
              {selectedManagementPracticesElements}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProgressDisplay
