import React from "react"
import SkillButton from "../buttons/SkillButton"
import Counter from "../Counter"

function SingleDeveloperOption({
  developer,
  toggleDeveloper,
  developerCountChange,
  toggleSelectSkill,
  skills,
}) {
  function handleDeveloperChange() {
    toggleDeveloper(developer.id)
  }

  // Skill buttons elements array (empty)
  const developerSkillElements = []

  // Fill skill buttons elements array
  developer.skills.forEach((skill) => {
    developerSkillElements.push(
      <SkillButton
        key={skill}
        alias={skill}
        toggleSelectSkill={toggleSelectSkill}
        skills={skills}
      />
    )
  })

  return (
    <div className="single-role-block">
      <label className="align-middle">
        <span className="custom-checkbox">
          <input
            type="checkbox"
            checked={developer.checked}
            onChange={handleDeveloperChange}
          />
          <span className="checkmark"></span>
        </span>
        <span className="font-weight-demi role-title">{developer.title}</span>
      </label>

      <div className="d-md-inline-block ml-3 mb-4 mb-md-1">
        {developer.checked && (
          <Counter
            roleId={developer.id}
            count={developer.count}
            roleCountChange={developerCountChange}
          />
        )}
      </div>

      {/* Developer skill buttons */}
      <div className="skills-wrapper mt-3 mb-4 w-full">
        {developer.checked && developerSkillElements}
      </div>
    </div>
  )
}

export default SingleDeveloperOption
