import React from "react"
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"

function RangeSlider({ min, max, step, initialValue, labels, updateSlider }) {
  function handleChange(value) {
    // Prevent the slider (0 value) select
    if (value > 0) {
      updateSlider(value)
    }
  }

  return (
    <Slider
      min={min}
      max={max}
      step={step}
      value={initialValue}
      labels={labels}
      tooltip={false}
      onChange={handleChange}
      className="custom-rangeslider"
    />
  )
}

export default RangeSlider
