import React from "react"

function SkillButton({ alias, toggleSelectSkill, skills }) {
  // Select skill for current button
  const skill = skills.find((skill) => skill.alias === alias)

  function handleSkillButtonClick() {
    toggleSelectSkill(alias)
  }

  // component class
  let className = "btn btn-skill "

  if (skill.selected) className += "selected"

  return (
    <button className={className} onClick={handleSkillButtonClick}>
      {skill.title}
    </button>
  )
}

export default SkillButton
