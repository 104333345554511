import React from "react"
import RangeSlider from "../RangeSlider"
import ToggleButton from "../buttons/ToggleButton"

function Section5({
  companySizes,
  setCompanySizes,
  pastExperience,
  setPastExperience,
}) {
  // Labels objects for range slider
  const rangeSliderLabels = {
    0: "Employees",
    1: "10",
    2: "50",
    3: "100",
    4: "200+",
  }

  /* In this case, consider the slider value equal to the timeline array item id */
  function updateCompanySizes(value) {
    const newCompanySizes = [...companySizes]

    // Find the timeline item that has the selected value
    const companySize = newCompanySizes.find(
      (companySize) => companySize.value === value
    )

    // Set all the other timeline item's selected -> false
    newCompanySizes.forEach((companySize, index, theArray) => {
      theArray[index].selected = false
    })

    // Set the selected timeline item's selected -> true
    companySize.selected = true

    // Set the timelines
    setCompanySizes(newCompanySizes)
  }

  // Find the selected value of timeline
  const rangeSliderInitialValue = companySizes.find(
    (companySize) => companySize.selected
  ).value

  return (
    <div>
      <h2>How many people are employed at your company?</h2>
      <div style={{ marginBottom: "80px" }}>
        <RangeSlider
          min={0}
          max={companySizes.length - 1}
          step={1}
          initialValue={rangeSliderInitialValue}
          labels={rangeSliderLabels}
          updateSlider={updateCompanySizes}
        />
      </div>
      <h2 className="mt-4">
        Have you worked with remote developers in the past?
      </h2>
      <div>
        <ToggleButton item={pastExperience} setItem={setPastExperience} />
      </div>
    </div>
  )
}

export default Section5
