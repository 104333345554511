import ctaWizardData from "../../ctaWizardData"

export const resetData = (
  setUserToken,
  setDevelopers,
  setSpecialists,
  setSkills,
  setOptions,
  setIndustries,
  setManagementPractices,
  setTimelines,
  setCompanySizes,
  setPastExperience,
  setProgressState
) => {
  setUserToken(null)
  setDevelopers(ctaWizardData.developerData)
  setSpecialists(ctaWizardData.specialistData)
  setSkills(ctaWizardData.skillData)
  setOptions(ctaWizardData.optionsData)
  setIndustries(ctaWizardData.industryData)
  setManagementPractices(ctaWizardData.managementPracticesData)
  setTimelines(ctaWizardData.timelineData)
  setCompanySizes(ctaWizardData.companySizeData)
  setPastExperience(ctaWizardData.pastExperience)
  // Progress state variable
  setProgressState(1)
}
