const managementPracticesData = [
  {
    id: 1,
    title: "DevOps",
    alias: "devops",
    selected: false,
  },

  {
    id: 2,
    title: "Agile-scrum",
    alias: "agile-scrum",
    selected: false,
  },
  {
    id: 3,
    title: "CI-CD",
    alias: "cicd",
    selected: false,
  },
  {
    id: 4,
    title: "Extreme programming",
    alias: "extreme-programming",
    selected: false,
  },
  {
    id: 5,
    title: "Lean development",
    alias: "lean-development",
    selected: false,
  },
  {
    id: 6,
    title: "Waterfall mode",
    alias: "waterfall-mode",
    selected: false,
  },
]

export default managementPracticesData
