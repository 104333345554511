import React from "react"

function SingleRadioOption({
  option,
  optionType,
  toggleSelectOption,
  radioOptionName,
}) {
  function handleRadioButtonChange() {
    toggleSelectOption(optionType, option.id)
  }

  return (
    <div className="single-radio-option">
      <label className="align-middle custom-radio">
        <span>
          <input
            type="radio"
            name={radioOptionName}
            onChange={handleRadioButtonChange}
            checked={option.checked}
          />
          <div className="check"></div>
        </span>
        <span className="font-weight-demi custom-radio-title ml-2">
          {option.title}
        </span>
      </label>
    </div>
  )
}

export default SingleRadioOption
