const industryData = [
  {
    title: "Retail",
    alias: "retail",
    selected: false,
  },
  {
    title: "eCommerce",
    alias: "ecommerce",
    selected: false,
  },
  {
    title: "Supply Chain",
    alias: "supply-chain",
    selected: false,
  },
  {
    title: "Real estate",
    alias: "real-estate",
    selected: false,
  },
  {
    title: "eLearning",
    alias: "elearning",
    selected: false,
  },
  {
    title: "Gaming",
    alias: "gaming",
    selected: false,
  },
  {
    title: "Entertainment",
    alias: "entertainment",
    selected: false,
  },
  {
    title: "Travel & hospitality",
    alias: "travel-hospitality",
    selected: false,
  },
  {
    title: "Financial",
    alias: "financial",
    selected: false,
  },
  {
    title: "Healthcare",
    alias: "healthcare",
    selected: false,
  },
  {
    title: "Telecommunication",
    alias: "telecommunication",
    selected: false,
  },
  {
    title: "Other",
    alias: "other",
    selected: false,
  },
]

export default industryData
