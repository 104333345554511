const skillData = [
  // frontend skills
  {
    title: "Vue.js",
    alias: "vue-f",
    type: "frontend",
    selected: false,
  },
  {
    title: "Angular",
    alias: "angular-f",
    type: "frontend",
    selected: false,
  },
  {
    title: "React.js",
    alias: "react-f",
    type: "frontend",
    selected: false,
  },
  {
    title: "JavaScript",
    alias: "javascript-f",
    type: "frontend",
    selected: false,
  },
  {
    title: "Bootstrap",
    alias: "bootstrap-f",
    type: "frontend",
    selected: false,
  },
  {
    title: "GitHub",
    alias: "github-f",
    selected: false,
  },
  {
    title: "Ember.js",
    alias: "ember-f",
    type: "frontend",
    selected: false,
  },
  {
    title: "JQuery",
    alias: "jquery-f",
    type: "frontend",
    selected: false,
  },
  {
    title: "CSS",
    alias: "css-f",
    type: "frontend",
    selected: false,
  },
  {
    title: "HTML",
    alias: "html-f",
    type: "frontend",
    selected: false,
  },
  {
    title: "Node",
    alias: "node-f",
    type: "frontend",
    selected: false,
  },

  // backend skills
  {
    title: "Python",
    alias: "python-b",
    type: "backend",
    selected: false,
  },
  {
    title: "Java",
    alias: "java-b",
    type: "backend",
    selected: false,
  },
  {
    title: "C/C++",
    alias: "c-cpp-b",
    type: "backend",
    selected: false,
  },
  {
    title: "JavaScript",
    alias: "javascript-b",
    type: "backend",
    selected: false,
  },
  {
    title: "PHP",
    alias: "php-b",
    type: "backend",
    selected: false,
  },
  {
    title: ".NET",
    alias: "dotnet-b",
    type: "backend",
    selected: false,
  },
  {
    title: "Scala",
    alias: "scala-b",
    type: "backend",
    selected: false,
  },
  {
    title: "Ruby",
    alias: "ruby-b",
    type: "backend",
    selected: false,
  },
  {
    title: "Node",
    alias: "node-b",
    type: "backend",
    selected: false,
  },
  {
    title: "Golang",
    alias: "golang-b",
    type: "backend",
    selected: false,
  },
  // mobile skills
  {
    title: "iOS",
    alias: "ios-m",
    type: "mobile",
    selected: false,
  },
  {
    title: "React Native",
    alias: "react-native-m",
    type: "mobile",
    selected: false,
  },
  {
    title: "Android",
    alias: "android-m",
    type: "mobile",
    selected: false,
  },
  {
    title: "Flutter",
    alias: "flutter-m",
    type: "mobile",
    selected: false,
  },
  {
    title: "Xamarin",
    alias: "xamarin-m",
    type: "mobile",
    selected: false,
  },
  {
    title: "Ionic",
    alias: "ionic-m",
    type: "mobile",
    selected: false,
  },
  // database skills
  {
    title: "MySQL",
    alias: "mysql-db",
    type: "database",
    selected: false,
  },
  {
    title: "Oracle",
    alias: "oracle-db",
    type: "database",
    selected: false,
  },
  {
    title: "Microsoft Azure",
    alias: "msazure-db",
    type: "database",
    selected: false,
  },
  {
    title: "MsSQL",
    alias: "mssql-db",
    type: "database",
    selected: false,
  },
  {
    title: "NoSQL",
    alias: "nosql-db",
    type: "database",
    selected: false,
  },
  {
    title: "PostgreSQL",
    alias: "postgresql-db",
    type: "database",
    selected: false,
  },
]

export default skillData
