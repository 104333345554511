import React from "react"
import { Link } from "react-router-dom"

function Section7() {
  return (
    <div>
      <div className="alert alert-warning message mb-4" role="alert">
        <u>
          <strong>
            Thanks for your interest in our staff augmentation services.
          </strong>
        </u>
        Our project management specialists are calculating the cost of your
        request.
      </div>
      <div className="row">
        <div className="col-md-8">
          <p>
            Let us know how we can get back to you with the detailed cost
            breakdown. If there was any uncertainty while filling the form,
            please arrange a call with one of our specialist to clarify
            everything and find out the cost.
          </p>
        </div>
        <div className="col-md-4">
          <img src="/assets/images/contact-image.svg" alt="Contact cover" />
        </div>
      </div>
      <h2>How would you like to connect?</h2>
      <div>
        <Link to="/email-form" className="mx-3">
          <button className="btn btn-img-link">
            <img src="/assets/images/icons/email-icon.svg" alt="" />
          </button>
        </Link>
        <span className="mx-3 h4">or</span>
        <a href="https://outlook.office365.com/owa/calendar/InitialConsultation@maytechweb.com.au/bookings/" target="_blank" className="mx-3">
          <button className="btn btn-img-link">
            <img src="/assets/images/icons/phone-icon.svg" alt="" />
          </button>
        </a>
      </div>
    </div>
  )
}

export default Section7
