import React from "react"

function SingleRadioOption({
  option,
  optionType,
  toggleSelectSingleOption,
  radioOptionName,
}) {
  function handleRadioButtonClick() {
    toggleSelectSingleOption(optionType, option.id, "toggle")
  }

  return (
    <div className="single-radio-option">
      <label className="align-middle custom-radio">
        <span>
          <input
            type="radio"
            name={radioOptionName}
            onClick={handleRadioButtonClick}
            checked={option.checked}
          />
          <div className="check"></div>
        </span>
        <span className="font-weight-demi custom-radio-title ml-2">
          {option.title}
        </span>
      </label>
    </div>
  )
}

export default SingleRadioOption
