const optionsData = {
  projectTypes: [
    {
      id: 1,
      title: "New Project",
      alias: "new-project",
      checked: false,
    },
    {
      id: 2,
      title: "An existing project that required more resources",
      alias: "existing-project",
      checked: false,
    },
    {
      id: 3,
      title: "Ongoing maintenance and support",
      alias: "ongoing-maintenance",
      checked: false,
    },
    {
      id: 4,
      title: "Doing my reasearch for an upcoming project",
      alias: "upcoming-project",
      checked: false,
    },
  ],

  startWorkingInDays: [
    {
      id: 1,
      title: "ASAP",
      alias: "asap",
      checked: false,
    },
    {
      id: 2,
      title: "Within 15 days",
      alias: "within-15-days",
      checked: false,
    },
    {
      id: 3,
      title: "Within 30 days",
      alias: "within-30-days",
      checked: false,
    },
    {
      id: 4,
      title: "Haven't decided yet",
      alias: "not-decided",
      checked: false,
    },
  ],

  engagementModels: [
    {
      id: 1,
      title: "Project placement",
      alias: "project-placement",
      checked: false,
    },
    {
      id: 2,
      title: "Block hours",
      alias: "block-hours",
      checked: false,
    },
    {
      id: 3,
      title: "Full-time equivalent",
      alias: "full-time",
      checked: false,
    },
    {
      id: 4,
      title: "Managed IT",
      alias: "managed-it",
      checked: false,
    },
    {
      id: 5,
      title: "Not sure yet",
      alias: "not-sure",
      checked: false,
    },
  ],
  timelineNegative: [
    {
      id: 1,
      title: "Haven't decided yet",
      alias: "havent-decided-yet",
      checked: false,
    },
  ],
}

export default optionsData
