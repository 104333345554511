import React from "react"

function Counter({ roleId, count, roleCountChange }) {
  function handleDecrementButtonClick() {
    if (count > 0) count--
    roleCountChange(roleId, count)
  }

  function handleIncrementButtonClick() {
    count++
    roleCountChange(roleId, count)
  }

  return (
    count !== null && (
      <div className="counter">
        <button
          onClick={handleDecrementButtonClick}
          className="btn-counter btn-decrement"
        ></button>
        <span className="count-box mx-2">{count}</span>
        <button
          onClick={handleIncrementButtonClick}
          className="btn-counter btn-increment"
        ></button>
      </div>
    )
  )
}

export default Counter
