import React from "react"
import SingleRadioOption from "../options/SingleRadioOption"
import ItemButton from "../buttons/ItemButton"

function Section6({
  options,
  toggleSelectOption,
  optionType,
  managementPractices,
  setManagementPractices,
  toggleSelectItem,
}) {
  // Empty array to build option elements
  const optionElements = []

  options[optionType].map((option, index) =>
    optionElements.push(
      <SingleRadioOption
        key={index}
        option={option}
        optionType={optionType}
        toggleSelectOption={toggleSelectOption}
        radioOptionName={optionType}
      />
    )
  )

  // Empty array to build management practices button elements
  const managementPracticesElements = []

  managementPractices.map((practice, index) =>
    managementPracticesElements.push(
      <ItemButton
        alias={practice.alias}
        toggleSelectItem={toggleSelectItem}
        items={managementPractices}
        setItems={setManagementPractices}
        multiple={true}
      />
    )
  )

  return (
    <div>
      <h2>What project management practices do you follow?</h2>
      <div className="mb-4">{managementPracticesElements}</div>
      <h2>What will be your preferred engagement model?</h2>
      <div>{optionElements}</div>
    </div>
  )
}

export default Section6
