const projectTypeData = [
  {
    id: 1,
    title: "New Project",
    alias: "new-project",
    checked: false,
  },
  {
    id: 2,
    title: "An existing project that required more resources",
    alias: "existing-project",
    checked: false,
  },
  {
    id: 3,
    title: "Ongoing maintenance and support",
    alias: "ongoing-maintenance",
    checked: false,
  },
  {
    id: 4,
    title: "Doing my reasearch for an upcoming project",
    alias: "upcoming-project",
    checked: false,
  },
]

export default projectTypeData
