import developerData from "./data/developerData"
import specialistData from "./data/specialistData"
import industryData from "./data/industryData"
import skillData from "./data/skillData"
import optionsData from "./data/optionsData"
import companySizeData from "./data/companySizeData"
import timelineData from "./data/timelineData"
import managementPracticesData from "./data/managementPracticesData"
import projectTypeData from "./data/projectTypeData"

const ctaWizardData = {
  developerData,
  specialistData,
  industryData,
  skillData,
  optionsData,
  companySizeData,
  timelineData,
  managementPracticesData,
  projectTypeData,
  pastExperience: false,
}

export default ctaWizardData
