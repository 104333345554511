import React from "react"
import SingleRadioOption from "../options/SingleRadioOption"
import ItemButton from "../buttons/ItemButton"

function Section3({
  options,
  toggleSelectOption,
  optionType,
  industries,
  setIndustries,
  toggleSelectItem,
}) {
  // Empty array to build option elements
  const optionElements = []

  options[optionType].map((option, index) =>
    optionElements.push(
      <SingleRadioOption
        key={index}
        option={option}
        optionType={optionType}
        toggleSelectOption={toggleSelectOption}
      />
    )
  )

  // Empty array to build industry button elements
  const industryButtonElements = []

  industries.map((industry, index) =>
    industryButtonElements.push(
      <ItemButton
        key={index}
        alias={industry.alias}
        items={industries}
        setItems={setIndustries}
        toggleSelectItem={toggleSelectItem}
        multiple={false}
      />
    )
  )

  return (
    <div>
      <h2>What type of project are you hiring for?</h2>
      <div className="mb-4">{optionElements}</div>
      <h2>Select the industry that relates to your project</h2>
      <div>{industryButtonElements}</div>
    </div>
  )
}

export default Section3
