import React, { useState } from "react"
import { Redirect } from "react-router-dom"

// import recaptcha
import ReCAPTCHA from "react-google-recaptcha"

// import functions
import { resetData } from "./functions"

export default function EmailForm({
  userToken,
  LOCAL_STORAGE_KEY,
  // setters
  setUserToken,
  setDevelopers,
  setSpecialists,
  setSkills,
  setOptions,
  setIndustries,
  setManagementPractices,
  setTimelines,
  setCompanySizes,
  setPastExperience,
  setProgressState,
}) {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [disableSubmit, setDisableSubmit] = useState(true)

  async function handleSubmit(e) {
    e.preventDefault()

    const url = "https://maytechweb.com.au/clients"

    const requestOptions = {
      method: "POST",
      headers: {
        'Host': 'maytechweb.com.au',
        'Content-Type': 'application/json',
        'Content-Length': 473
      },
      body: JSON.stringify({
        uuid: userToken,
        first_name: firstName,
        last_name: lastName,
        email: email,
        company_name: companyName,
        form_type: "email",
      }),
    }

    const response = await fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }

        localStorage.removeItem(LOCAL_STORAGE_KEY)
        resetData(
          setUserToken,
          setDevelopers,
          setSpecialists,
          setSkills,
          setOptions,
          setIndustries,
          setManagementPractices,
          setTimelines,
          setCompanySizes,
          setPastExperience,
          setProgressState
        )

        return response
      })
      .then((data) => setSuccessMessage("Data successfully submitted."))
      .catch((error) => console.log("Error", error))
  }

  // Redirect to the success route
  if (successMessage) {
    return <Redirect to="/redirect-success" />
  }

  return (
    <div>
      <h2>Request the cost via email</h2>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="form-group">
          <input
            type="text"
            name="first_name"
            className="form-control"
            placeholder="First name*"
            onChange={(e) => setFirstName(e.target.value)}
            required
          ></input>
        </div>
        <div className="form-group">
          <input
            type="text"
            name="last_name"
            className="form-control"
            placeholder="Last name*"
            onChange={(e) => setLastName(e.target.value)}
            required
          ></input>
        </div>
        <div className="form-group">
          <input
            type="email"
            name="email"
            className="form-control"
            placeholder="Email*"
            onChange={(e) => setEmail(e.target.value)}
            required
          ></input>
        </div>
        <div className="form-group">
          <input
            type="text"
            name="company_name"
            className="form-control"
            placeholder="Company name*"
            onChange={(e) => setCompanyName(e.target.value)}
            required
          ></input>
        </div>

        <p>
          By completing signup, you are agreeing to Maytech’s Terms of Service,
          Privacy Policy, and Cookie Policy and that Maytech may monitor or
          record audio or video calls for quality assurance and training
          purposes.
        </p>

        {successMessage && <p class="text-success">{successMessage}</p>}

        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={() => setDisableSubmit(false)}
        />

        <button
          type="submit"
          class="btn btn-primary mt-3"
          disabled={disableSubmit}
        >
          Email me the details
        </button>
      </form>
    </div>
  )
}
