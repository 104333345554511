import React from "react"

function SkillCard({ skillAlias }) {
  let className = "skill-card "
  let imgSrc = "/assets/images/logos/"

  // Generate the class name
  skillAlias && (className += "active ")

  // Generate the skill logo path
  imgSrc && (imgSrc += skillAlias + ".svg")

  return (
    <div className={className}>
      <img src={imgSrc} alt={skillAlias} />
    </div>
  )
}

export default SkillCard
