import React from "react"

/* 
  If multiple==true, then multiple buttons can be selected.
  Else only one button can be selected.
*/
function ItemButton({ alias, toggleSelectItem, items, setItems, multiple }) {
  // Select item for current button
  const item = items.find((item) => item.alias === alias)

  function handleItemButtonClick() {
    toggleSelectItem(alias, items, setItems, multiple)
  }

  // component class
  let className = "btn btn-skill "

  if (item.selected) className += "selected"

  return (
    <button className={className} onClick={handleItemButtonClick}>
      {item.title}
    </button>
  )
}

export default ItemButton
