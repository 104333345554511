import React from "react"

function ToggleButton({ item, setItem, toggleItem }) {
  let className = "btn btn-toggle px-4 "

  const btnLabels = ["Yes", "No"]

  function handleClick() {
    //toggleItem
    setItem(!item)
  }

  const Button1 = (
    <button
      type="button"
      className={className + (item ? "active" : "")}
      onClick={handleClick}
    >
      {btnLabels[0]}
    </button>
  )

  const Button2 = (
    <button
      type="button"
      className={className + (item ? "" : "active")}
      onClick={handleClick}
    >
      {btnLabels[1]}
    </button>
  )

  return (
    <div
      class="btn-group toggle-btn-group"
      role="group"
      aria-label="Toggle button group"
    >
      {Button1}
      {Button2}
    </div>
  )
}

export default ToggleButton
