import React from "react"
import Counter from "../Counter"

function singleSpecialistOption({
  specialist,
  toggleSpecialist,
  specialistCountChange,
}) {
  function handleSpecialistChange() {
    toggleSpecialist(specialist.id)
  }

  return (
    <div className="single-role-block">
      <label className="align-middle">
        <span className="custom-checkbox">
          <input
            type="checkbox"
            checked={specialist.checked}
            onChange={handleSpecialistChange}
          />
          <span className="checkmark"></span>
        </span>
        <span className="font-weight-demi role-title">{specialist.title}</span>
      </label>

      <div className="d-inline-block ml-3">
        {specialist.checked && (
          <Counter
            roleId={specialist.id}
            count={specialist.count}
            roleCountChange={specialistCountChange}
          />
        )}
      </div>
    </div>
  )
}

export default singleSpecialistOption
