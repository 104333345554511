import React from "react"

export default function ProgressBar({ now }) {
  let style = {
    width: (now / 7) * 100 + "%",
    borderRadius: now === 7 ? "0" : "0 8px 8px 0",
  }

  return (
    <div class="progress" id="progress-wrapper">
      <div
        class="progress-bar"
        role="progressbar"
        style={style}
        aria-valuenow="0"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  )
}
