import React from "react"
import { Link, useLocation } from "react-router-dom"
import CalculateButton from "../buttons/CalculateButton"

// Import fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRight,
  faArrowLeft,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons"

function Navigation({
  userToken,
  developers,
  specialists,
  selectedIndustry,
  selectedTimeline,
  selectedCompanySize,
  pastExperience,
  setProgressState,
  options,
  skills,
  managementPractices,
  setShowPastExperienceCard,
}) {
  const location = useLocation()
  let nextButtonLink
  let backButtonLink
  let skipButtonLink

  /*  Validation functions */
  function validateSectionOne() {
    // Validate section1
    const selectedDeveloperArray = developers.filter(
      (developer) => developer.checked
    )

    if (selectedDeveloperArray.length > 0) {
      return true
    }
  }

  function validateSectionTwo() {
    // Validate section2
    const selecteSpecialistArray = specialists.filter(
      (specialist) => specialist.checked
    )

    if (selecteSpecialistArray.length > 0) {
      return true
    }
  }

  function validateSectionThree() {
    const isProjectTypeSelected = options["projectTypes"].find(
      (projectType) => projectType.checked
    )
      ? true
      : false
    if (selectedIndustry && isProjectTypeSelected) {
      return true
    }
  }

  function validateSectionFour() {
    const isStartWorkingInDaysSelected = options["startWorkingInDays"].find(
      (item) => item.checked
    )
      ? true
      : false

    if (
      isStartWorkingInDaysSelected &&
      (selectedTimeline.value || options["timelineNegative"][0].checked)
    ) {
      return true
    }
  }

  function validateSectionSix() {
    const isEngagementModelSelected = options["engagementModels"].find(
      (item) => item.checked
    )
      ? true
      : false

    const isManagementPracticesSelected = managementPractices.find(
      (item) => item.selected
    )
      ? true
      : false

    if (isEngagementModelSelected && isManagementPracticesSelected) {
      return true
    }
  }

  switch (location.pathname) {
    case "/":
      if (validateSectionOne()) {
        nextButtonLink = "/section2"
      }
      setProgressState(1)
      skipButtonLink = "/section2"
      break
    case "/section2":
      if (validateSectionTwo()) {
        nextButtonLink = "/section3"
      }
      setProgressState(2)
      backButtonLink = "/"
      skipButtonLink = "/section3"
      break
    case "/section3":
      if (validateSectionThree()) {
        nextButtonLink = "/section4"
      }
      backButtonLink = "/section2"
      setProgressState(3)
      break
    case "/section4":
      if (validateSectionFour()) {
        nextButtonLink = "/section5"
      }
      backButtonLink = "/section3"
      setProgressState(4)
      break
    case "/section5":
      nextButtonLink = "/section6"
      backButtonLink = "/section4"
      setProgressState(5)
      break
    case "/section6":
      backButtonLink = "/section5"
      setProgressState(6)
      break
    default:
      setProgressState(7)
      break
  }

  function handleNextButtonClick() {
    switch (location.pathname) {
      case "/":
        if (!validateSectionOne()) {
          alert("Please select the fields required or click the skip button")
        }
        break
      case "/section2":
        if (!validateSectionTwo()) {
          alert("Please select the fields required or click the skip button")
        }
        break
      case "/section3":
        if (!validateSectionThree()) {
          alert("Please select the fields required")
        }
        break
      case "/section4":
        if (!validateSectionFour()) {
          alert("Please select the fields required")
        } else {
          setShowPastExperienceCard(true)
        }
        break
      case "/section6":
        if (!validateSectionSix()) {
          alert("Please select the fields required")
        }
        break
    }
  }

  if (
    location.pathname !== "/section7" &&
    location.pathname !== "/email-form" &&
    location.pathname !== "/call-form"
  ) {
    return (
      <>
        <div id="navigation" className="py-3 d-flex justify-content-between">
          <div>
            {
              // If in the homepage -> hide back button, else show.
              location.pathname !== "/" && (
                <Link to={backButtonLink}>
                  <button className="btn btn-back">
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                    Back
                  </button>
                </Link>
              )
            }
          </div>
          <div>
            <Link to={skipButtonLink}>
              {(location.pathname === "/" ||
                location.pathname === "/section2") && (
                <button className="btn btn-skip mr-3">Skip</button>
              )}
            </Link>
            {location.pathname !== "/section6" && (
              <Link to={nextButtonLink} onClick={handleNextButtonClick}>
                <button className="btn btn-next">
                  Next
                  <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                </button>
              </Link>
            )}
            {location.pathname === "/section6" && (
              <CalculateButton
                {...{
                  userToken,
                  developers,
                  specialists,
                  selectedIndustry,
                  selectedTimeline,
                  selectedCompanySize,
                  pastExperience,
                  options,
                  skills,
                  managementPractices,
                  validateSectionSix,
                }}
              />
            )}
          </div>
        </div>
        <footer className="mt-4 font-weight-demi">
          <div className="d-md-flex justify-content-between">
            <div>
              I would rather talk to someone{" "}
              <a href="https://wa.me/+61432836911">WhatsApp</a>,{" "}
              {/* <a href="#">Zapier</a>,{" "} */}
              <a href="mailto:info@maytechweb.com.au">Email</a>
            </div>
            <div className="my-2 my-md-0">
              <a href="https://outlook.office365.com/owa/calendar/InitialConsultation@maytechweb.com.au/bookings/" target="_blank" className="text-orange">
                <FontAwesomeIcon icon={faPhoneAlt} className="mr-2" />
                Skip the process and arrange a call
              </a>
            </div>
          </div>
        </footer>
      </>
    )
  } else {
    return null
  }
}

export default Navigation
