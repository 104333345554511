const developerData = [
  {
    id: 1,
    title: "Frontend developers",
    name: "frontendDevelopers",
    alias: "frontend",
    skills: [
      "vue-f",
      "angular-f",
      "react-f",
      "javascript-f",
      "bootstrap-f",
      "github-f",
      "ember-f",
      "jquery-f",
      "css-f",
      "html-f",
      "node-f",
    ],
    count: 0,
    checked: false,
  },
  {
    id: 2,
    title: "Backendend developers",
    name: "backendDevelopers",
    alias: "backend",
    skills: [
      "python-b",
      "java-b",
      "c-cpp-b",
      "javascript-b",
      "php-b",
      "dotnet-b",
      "scala-b",
      "ruby-b",
      "node-b",
      "golang-b",
    ],
    count: 0,
    checked: false,
  },
  {
    id: 3,
    title: "Mobile app developers",
    name: "mobileAppDevelopers",
    alias: "mobile",
    skills: [
      "ios-m",
      "react-native-m",
      "android-m",
      "flutter-m",
      "xamarin-m",
      "ionic-m",
    ],
    count: 0,
    checked: false,
  },
  {
    id: 4,
    title: "Database developers",
    name: "databaseDevelopers",
    alias: "database",
    skills: [
      "mysql-db",
      "oracle-db",
      "msazure-db",
      "mssql-db",
      "nosql-db",
      "postgresql-db",
    ],
    count: 0,
    checked: false,
  },
]

export default developerData
