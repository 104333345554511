import React from "react"
import SingleSpecialistOption from "../options/SingleSpecialistOption"

function Section2({ specialists, setSpecialists }) {
  // Empty array to build specialist options
  const specialistOptionElements = []

  function toggleSpecialist(id) {
    // Set current specialists array to a temporary new array
    const newSpecialists = [...specialists]

    // Find the specialist array element that matches the provided id
    const specialist = newSpecialists.find((specialist) => specialist.id === id)

    // Toggle specialist checked/ not
    specialist.checked = !specialist.checked

    // Turn count to 0 if deselect the specialist option
    if (!specialist.checked) {
      if (specialist.count !== null) {
        specialist.count = 0
      }
    }

    // Set the new specialists array
    setSpecialists(newSpecialists)
  }

  function specialistCountChange(id, count) {
    // Set current specialist array to a temporary new array
    const newSpecialists = [...specialists]

    // Find the specialist array element that matches the provided id
    const specialist = newSpecialists.find((specialist) => specialist.id === id)

    // Update specialist count
    specialist.count = count

    // Set the new specialists array
    setSpecialists(newSpecialists)
  }

  specialists.forEach((specialist) => {
    specialistOptionElements.push(
      <SingleSpecialistOption
        key={specialist.id}
        specialist={specialist}
        toggleSpecialist={toggleSpecialist}
        specialistCountChange={specialistCountChange}
      />
    )
  })

  return (
    <div>
      <h2>
        Decide the project management specialist/ specialisation you required
      </h2>
      <div>{specialistOptionElements}</div>
    </div>
  )
}

export default Section2
