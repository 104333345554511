import React, { useState } from "react"
import { Redirect } from "react-router-dom"

import DatePicker from "react-datepicker"
import { addDays } from "date-fns"
import "react-datepicker/dist/react-datepicker.css"
import PhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"
import TimezoneInput from "./form-parts/TimezoneInput"
import TimeInput from "./form-parts/TimeInput"

// import recaptcha
import ReCAPTCHA from "react-google-recaptcha"

// import functions
import { resetData } from "./functions"

export default function CallForm({
  userToken,
  LOCAL_STORAGE_KEY,
  // setters
  setUserToken,
  setDevelopers,
  setSpecialists,
  setSkills,
  setOptions,
  setIndustries,
  setManagementPractices,
  setTimelines,
  setCompanySizes,
  setPastExperience,
  setProgressState,
}) {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [internetCallType, setInternetCallType] = useState("skype")
  const [internetCallId, setInternetCallId] = useState("")
  const [timezone, setTimezone] = useState("")
  const [callDate, setCallDate] = useState(null)
  const [callTime, setTime] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [disableSubmit, setDisableSubmit] = useState(true)

  async function handleSubmit(e) {
    e.preventDefault()

    const url = "https://maytechweb.com.au/clients"

    const requestOptions = {
      method: "POST",
      headers: {
        'Host': 'maytechweb.com.au',
        'Content-Type': 'application/json',
        'Content-Length': 473
      },
      body: JSON.stringify({
        uuid: userToken,
        first_name: firstName,
        last_name: lastName,
        email: email,
        company_name: companyName,
        phone_number: phoneNumber,
        internet_call_type: internetCallType,
        internet_call_id: internetCallId,
        timezone: timezone,
        call_date: callDate,
        call_time: callTime,
        form_type: "call",
      }),
    }

    const response = await fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }

        localStorage.removeItem(LOCAL_STORAGE_KEY)
        resetData(
          setUserToken,
          setDevelopers,
          setSpecialists,
          setSkills,
          setOptions,
          setIndustries,
          setManagementPractices,
          setTimelines,
          setCompanySizes,
          setPastExperience,
          setProgressState
        )

        return response
      })
      .then((data) => setSuccessMessage("Data successfully submitted."))
      .catch((error) => console.log("Error", error))
  }

  let internetCallTypeText

  switch (internetCallType) {
    case "skype":
      internetCallTypeText = "Skype ID"
      break
    case "whatsapp":
      internetCallTypeText = "Whatsapp number"
      break
    case "viber":
      internetCallTypeText = "Viber number"
      break
    case "hangouts":
      internetCallTypeText = "Hangouts ID"
      break
    case "zoom":
      internetCallTypeText = "Zoom ID"
      break
    case "slack":
      internetCallTypeText = "Slack"
      break
    case "msteams":
      internetCallTypeText = "MS Teams"
      break
    default:
      internetCallTypeText = "Skype ID"
      break
  }

  // Redirect to the success route
  if (successMessage) {
    return <Redirect to="/redirect-success" />
  }

  return (
    <div>
      <h2>Arrange a call with one of our specialists</h2>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="form-group">
          <input
            type="text"
            required
            className="form-control"
            placeholder="First name"
            onChange={(e) => setFirstName(e.target.value)}
          ></input>
        </div>
        <div className="form-group">
          <input
            type="text"
            required
            className="form-control"
            placeholder="Last name"
            onChange={(e) => setLastName(e.target.value)}
          ></input>
        </div>
        <div className="form-group">
          <input
            type="email"
            required
            className="form-control"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          ></input>
        </div>
        <div className="form-group">
          <input
            type="text"
            required
            className="form-control"
            placeholder="Company name"
            onChange={(e) => setCompanyName(e.target.value)}
          ></input>
        </div>
        <div className="form-group">
          <div className="form-control">
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              defaultCountry="AU"
              value={phoneNumber}
              onChange={setPhoneNumber}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="d-flex custom-input-group">
            <div id="internet-call-type-select" className="select-wrapper">
              <select
                className="form-control"
                onChange={(e) => setInternetCallType(e.target.value)}
              >
                <option
                  value="skype"
                  selected={internetCallId === "skype" ? true : false}
                >
                  Skype
                </option>
                <option
                  value="zoom"
                  selected={internetCallId === "zoom" ? true : false}
                >
                  Zoom
                </option>
                <option
                  value="whatsapp"
                  selected={internetCallId === "whatsapp" ? true : false}
                >
                  Whatsapp
                </option>
                <option
                  value="viber"
                  selected={internetCallId === "viber" ? true : false}
                >
                  Viber
                </option>
                <option
                  value="hangouts"
                  selected={internetCallId === "hangouts" ? true : false}
                >
                  Hangouts
                </option>
                <option
                  value="slack"
                  selected={internetCallId === "slack" ? true : false}
                >
                  Slack
                </option>
                <option
                  value="msteams"
                  selected={internetCallId === "msteams" ? true : false}
                >
                  Teams
                </option>
              </select>
            </div>

            <div className="w-100">
              <input
                type="text"
                className="form-control"
                placeholder={internetCallTypeText}
                onChange={(e) => setInternetCallId(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="select-wrapper">
            <TimezoneInput {...{ setTimezone }} />
          </div>
        </div>

        <div className="row">
          <div className="col-7 pr-2">
            <div className="select-wrapper">
              <DatePicker
                selected={callDate}
                onChange={(date) => setCallDate(date)}
                placeholderText="Date"
                minDate={addDays(new Date(), 1)}
                className="form-control"
                style={{
                  backgroundImage:
                    "url('/assets/images/icons/globe-solid.svg')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "20px 20px",
                  backgroundPosition: "10px center",
                  paddingLeft: "40px",
                }}
              />
            </div>
          </div>
          <div className="col-5 pl-2">
            <div className="select-wrapper">
              <TimeInput {...{ setTime }} />
            </div>
          </div>
        </div>

        <p className="my-4">
          By completing signup, you are agreeing to Maytech’s Terms of Service,
          Privacy Policy, and Cookie Policy and that Maytech may monitor or
          record audio or video calls for quality assurance and training
          purposes.
        </p>

        {successMessage && <p class="text-success">{successMessage}</p>}

        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={() => setDisableSubmit(false)}
        />

        <button
          type="submit"
          class="btn btn-primary mt-3"
          disabled={disableSubmit}
        >
          Request a call
        </button>
      </form>
    </div>
  )
}
