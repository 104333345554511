import React from "react"

function RoleCard({ roleAlias, roleCount }) {
  let className = "role-card mx-1 mx-md-2 my-1 text-center "
  let imgSrc = "/assets/images/roles/"

  // If roleCount > 0 then normal image, else faded image
  imgSrc += roleAlias + (roleCount ? ".svg" : "-faded.svg")

  roleCount && (className += "active")

  return (
    <div className={className}>
      <img src={imgSrc} alt="" className="img-fluid" />
      <span className="count">{roleCount} </span>
    </div>
  )
}

export default RoleCard
