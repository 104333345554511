const timelineData = [
  {
    id: 1,
    title: "",
    value: 0,
    selected: true,
  },
  {
    id: 2,
    title: "1 month",
    value: 1,
    selected: false,
  },
  {
    id: 3,
    title: "1-3 months",
    value: 2,
    selected: false,
  },
  {
    id: 4,
    title: "3-6 months",
    value: 3,
    selected: false,
  },
  {
    id: 5,
    title: "1+ years",
    value: 4,
    selected: false,
  },
]

export default timelineData
