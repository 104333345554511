import React from "react"
import SingleRadioOption from "../options/SingleRadioOption"
import ToggleRadioOption from "../options/ToggleRadioOption"
import RangeSlider from "../RangeSlider"

function Section4({
  options,
  toggleSelectOption,
  toggleSelectSingleOption,
  optionType,
  timelines,
  setTimelines,
}) {
  // Empty array to build option elements
  const optionElements = []

  options[optionType].map((option, index) =>
    optionElements.push(
      <SingleRadioOption
        key={index}
        option={option}
        optionType={optionType}
        toggleSelectOption={toggleSelectOption}
        radioOptionName={optionType}
      />
    )
  )

  // Labels objects for range slider
  const rangeSliderLabels = {
    0: "Months",
    1: "1/mo",
    2: "3/mo",
    3: "6/mo",
    4: "1+yr",
  }

  // Stype for

  /* In this case, consider the slider value equal to the timeline array item id */
  function updateTimelines(value) {
    const newTimelines = [...timelines]

    // Find the timeline item that has the selected value
    const timeline = newTimelines.find((timeline) => timeline.value === value)

    // Set all the other timeline item's selected -> false
    newTimelines.forEach((timeline, index, theArray) => {
      theArray[index].selected = false
    })

    // Set the selected timeline item's selected -> true
    timeline.selected = true

    // Set the timelines
    setTimelines(newTimelines)
  }

  // Find the selected value of timeline
  const rangeSliderInitialValue = timelines.find(
    (timeline) => timeline.selected
  ).value

  return (
    <div>
      <h2>How long do you need the resources?</h2>
      <div
        style={
          options["timelineNegative"][0].checked
            ? { marginBottom: "40px", opacity: 0.3 }
            : { marginBottom: "40px" }
        }
        onClick={() =>
          toggleSelectSingleOption("timelineNegative", 1, "toFalse")
        }
      >
        <RangeSlider
          min={0}
          max={timelines.length - 1}
          step={1}
          initialValue={rangeSliderInitialValue}
          labels={rangeSliderLabels}
          updateSlider={updateTimelines}
        />
      </div>
      <div style={{ marginBottom: "40px" }}>
        <div style={{ marginBottom: "10px", paddingLeft: "20px" }}>
          <b>or</b>
        </div>
        <ToggleRadioOption
          option={options["timelineNegative"][0]}
          optionType="timelineNegative"
          toggleSelectSingleOption={toggleSelectSingleOption}
        />
      </div>
      <h2 className="mt-4">How soon do you want them to start working?</h2>
      <div>{optionElements}</div>
    </div>
  )
}

export default Section4
