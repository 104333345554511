const companySizeData = [
  {
    id: 1,
    title: "",
    value: 0,
    selected: true,
  },
  {
    id: 2,
    title: "Below 10",
    value: 1,
    selected: false,
  },
  {
    id: 3,
    title: "11 to 50",
    value: 2,
    selected: false,
  },
  {
    id: 4,
    title: "50 to 100",
    value: 3,
    selected: false,
  },
  {
    id: 5,
    title: "100 to 200",
    value: 4,
    selected: false,
  },
]

export default companySizeData
