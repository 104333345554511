import React from "react"
import { Link } from "react-router-dom"

function Header() {
  return (
    <header style={{ marginBottom: "24px" }}>
      <Link to="/redirect-landing">
        <div className="brand-logo pl-3">
          <img
            src="/assets/images/maytech_logo_new.svg"
            alt="Matech Technologies logo"
          />
        </div>
      </Link>
    </header>
  )
}

export default Header
