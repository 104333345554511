import React from "react"
import SingleDeveloperOption from "../options/SingleDeveloperOption"

function Section1({
  developers,
  setDevelopers,
  skills,
  setSkills,
  toggleSelectSkill,
}) {
  const developerOptionElements = []
  //const [developers, setDevelopers] = useState(developerData)

  // Function for toggling skills with buttons
  function deSelectSkillSet(type) {
    // Set current skills array to a temporary new array
    const newSkills = [...skills]

    // Find the skills that matches the provided type
    newSkills.forEach((skill, index) => {
      if (skill.type === type) {
        // Deselect the skill
        newSkills[index].selected = false
      }
    })

    // Set the new skills array
    setSkills(newSkills)
  }

  function toggleDeveloper(id) {
    // Set current developers array to a temporary new array
    const newDevelopers = [...developers]

    // Find the developer array element that matches the provided id
    const developer = newDevelopers.find((developer) => developer.id === id)

    // Toggle developer checked/ not
    developer.checked = !developer.checked

    // Turn count to 0 if deselect the developer option
    if (!developer.checked) {
      developer.count = 0
      // Deselect the related skillset
      deSelectSkillSet(developer.alias)
    }

    // Set the new developers array
    setDevelopers(newDevelopers)
    console.log(1)
  }

  function developerCountChange(id, count) {
    // Set current developers array to a temporary new array
    const newDevelopers = [...developers]

    // Find the developer array element that matches the provided id
    const developer = newDevelopers.find((developer) => developer.id === id)

    // Update developer count
    developer.count = count

    // Set the new developers array
    setDevelopers(newDevelopers)
  }

  developers.forEach((developer) =>
    developerOptionElements.push(
      <SingleDeveloperOption
        key={developer.id}
        developer={developer}
        toggleDeveloper={toggleDeveloper}
        developerCountChange={developerCountChange}
        skills={skills}
        toggleSelectSkill={toggleSelectSkill}
      />
    )
  )

  return (
    <div>
      <div className="alert alert-warning message mb-4" role="alert">
        <u>
          <strong>Thanks for your interest</strong>
        </u>{" "}
        in our staff augmentation solutions. Let’s get started by defining your
        project scope !
      </div>
      <h2>Select the number of developers &amp; their skillset</h2>
      <div>{developerOptionElements}</div>
    </div>
  )
}

export default Section1
