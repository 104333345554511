import React from "react"

// Import fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"

function DetailCard({ cardContent, iconName }) {
  let className = "detail-card text-white "

  cardContent && (className += "active")

  return (
    <div className={className}>
      {iconName && (
        <FontAwesomeIcon
          icon={iconName === "faPlus" ? faPlus : null}
          size="xs"
        />
      )}{" "}
      {cardContent}
    </div>
  )
}

export default DetailCard
