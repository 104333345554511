const specialistData = [
  {
    id: 1,
    title: "Project manager",
    alias: "project-manager",
    count: 0,
    checked: false,
  },
  {
    id: 2,
    title: "UI/ UX designer",
    alias: "uiux-designer",
    count: 0,
    checked: false,
  },
  {
    id: 3,
    title: "Business analyst",
    alias: "business-analyst",
    count: 0,
    checked: false,
  },
  {
    id: 4,
    title: "DevOps specialist/ Scrum master",
    alias: "devops-specialist",
    count: 0,
    checked: false,
  },
  {
    id: 5,
    title: "QA engineer",
    alias: "qa-engineer",
    count: 0,
    checked: false,
  },
  {
    id: 6,
    title: "Remote maintenance",
    alias: "remote-maintenance",
    count: 0,
    checked: false,
  },
  {
    id: 7,
    title: "Don't know the specialisations required",
    alias: "no-specialisations",
    count: null,
    checked: false,
  },
]

export default specialistData
