import React, { useState, useEffect } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import Section1 from "./components/sections/Section1"
import Section2 from "./components/sections/Section2"
import Section3 from "./components/sections/Section3"
import Section4 from "./components/sections/Section4"
import Section5 from "./components/sections/Section5"
import Section6 from "./components/sections/Section6"
import Section7 from "./components/sections/Section7"
import EmailForm from "./components/sections/EmailForm"
import CallForm from "./components/sections/CallForm"

import Header from "./components/layouts/Header"
import Navigation from "./components/layouts/Navigation"
import ProgressDisplay from "./components/ProgressDisplay"
import ProgressBar from "./components/ProgressBar"

import ctaWizardData from "./ctaWizardData"

const LOCAL_STORAGE_KEY = "ctaWizardData"

function App() {
  let token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
  token = token ? token.userToken : null
  const [userToken, setUserToken] = useState(token)

  if (!userToken) {
    const TokenGenerator = require("uuid-token-generator")
    const token = new TokenGenerator() // Default is a 128-bit token encoded in base58
    setUserToken(token.generate())
  }

  const [developers, setDevelopers] = useState(ctaWizardData.developerData)
  const [specialists, setSpecialists] = useState(ctaWizardData.specialistData)
  const [skills, setSkills] = useState(ctaWizardData.skillData)
  const [options, setOptions] = useState(ctaWizardData.optionsData)
  const [industries, setIndustries] = useState(ctaWizardData.industryData)
  const [managementPractices, setManagementPractices] = useState(
    ctaWizardData.managementPracticesData
  )
  const [timelines, setTimelines] = useState(ctaWizardData.timelineData)
  const [companySizes, setCompanySizes] = useState(
    ctaWizardData.companySizeData
  )
  const [pastExperience, setPastExperience] = useState(
    ctaWizardData.pastExperience
  )

  // Progress state variable
  const [progressState, setProgressState] = useState(1)

  // Card display state variables
  const [showPastExperienceCard, setShowPastExperienceCard] = useState(false)

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
    /* Check whether local storage has data */
    if (storedData) {
      // If yes? get values from local storage
      setDevelopers(storedData.developers)
      setSpecialists(storedData.specialists)
      setSkills(storedData.skills)
      setOptions(storedData.options)
      setIndustries(storedData.industries)
      setManagementPractices(storedData.managementPractices)
      setTimelines(storedData.timelines)
      setCompanySizes(storedData.companySizes)
      setPastExperience(storedData.pastExperience)
    }
  }, [])

  // Ready to store in local storage
  const storedCtaWizardData = {
    userToken: userToken,
    developers,
    specialists,
    skills,
    options,
    industries,
    managementPractices,
    timelines,
    companySizes,
    pastExperience,
  }

  // Update local storage when main data array updated
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(storedCtaWizardData))
  }, [storedCtaWizardData])

  // Function for toggling skills with buttons
  function toggleSelectSkill(alias) {
    // Set current skills array to a temporary new array
    const newSkills = [...skills]

    // Find the skills array element that matches the provided alias
    const skill = newSkills.find((skill) => skill.alias === alias)

    // Toggle skill selected/ not
    skill.selected = !skill.selected

    // Set the new skills array
    setSkills(newSkills)
  }

  // Function for toggling itemss with buttons
  function toggleSelectItem(alias, items, setItems, multiple) {
    // Set current items array to a temporary new array
    const newItems = [...items]

    // Find the items array element that matches the provided alias
    const item = newItems.find((item) => item.alias === alias)
    const otherItems = newItems.filter((item) => item.alias !== alias)

    // Select the clicked button and deselect all of the others
    if (!item.selected && !multiple) {
      item.selected = !item.selected
      otherItems.forEach((item) => (item.selected = false))
    }

    if (multiple) {
      item.selected = !item.selected
    }

    // Set the new items array
    setItems(newItems)
  }

  // Function for toggle radio button options
  function toggleSelectOption(optionType, optionId) {
    // Set current options array to a temporary new array
    const newOptions = { ...options }

    // Select the current option
    const option = newOptions[optionType].find(
      (option) => option.id === optionId
    )

    const selectedOption = newOptions[optionType].find(
      (option) => option.checked
    )
    selectedOption && (selectedOption.checked = false)

    // Toggle option checked/ not
    if (!option.checked) {
      option.checked = !option.checked
    }

    // Set the new options array
    setOptions(newOptions)
  }

  // Function for toggle (single) radio button option
  function toggleSelectSingleOption(optionType, optionId, convertion) {
    // Set current options array to a temporary new array
    const newOptions = { ...options }

    // Select the current option
    const option = newOptions[optionType].find(
      (option) => option.id === optionId
    )

    if (convertion === "toggle") {
      // Toggle option checked/ not
      option.checked = !option.checked
    } else if (convertion === "toTrue") {
      option.checked = true
    } else if (convertion === "toFalse") {
      option.checked = false
    }

    // Set the new options array
    setOptions(newOptions)
  }

  // Get the selected industry object -> title
  let selectedIndustry = industries.find((industry) => industry.selected)
  selectedIndustry = selectedIndustry && selectedIndustry.title

  // Get the selected timeline object -> title
  const selectedTimeline = timelines.find((timeline) => timeline.selected)
  //selectedTimeline = selectedTimeline && selectedTimeline.title

  // Get the selected company size -> title
  let selectedCompanySize = companySizes.find(
    (companySize) => companySize.selected
  )
  selectedCompanySize = selectedCompanySize && selectedCompanySize.title

  // Validate section2
  const selectedSpecialistCount = specialists.filter(
    (specialist) => specialist.checked
  ).length

  return (
    <Router>
      <div className="container-fluid">
        <div className="row">
          {/* Left column */}
          <div className="col-lg-7 pt-5 pr-5 pb-5">
            <div style={{ maxWidth: "680px", margin: "0 auto" }}>
              <Header />
              <div className="content-wrapper px-3">
                <Switch>
                  <Route path="/section2">
                    <Section2 {...{ specialists, setSpecialists }} />
                  </Route>
                  <Route path="/section3">
                    <Section3
                      {...{
                        options,
                        setOptions,

                        toggleSelectOption,
                        industries,
                        setIndustries,
                        toggleSelectItem,
                      }}
                      optionType="projectTypes"
                    />
                  </Route>
                  <Route path="/section4">
                    <Section4
                      {...{
                        options,
                        toggleSelectOption,
                        toggleSelectSingleOption,
                        timelines,
                        setTimelines,
                      }}
                      optionType="startWorkingInDays"
                    />
                  </Route>
                  <Route path="/section5">
                    <Section5
                      {...{
                        companySizes,
                        setCompanySizes,
                        pastExperience,
                        setPastExperience,
                      }}
                    />
                  </Route>
                  <Route path="/section6">
                    <Section6
                      {...{
                        options,
                        toggleSelectOption,
                        managementPractices,
                        setManagementPractices,
                        toggleSelectItem,
                      }}
                      optionType="engagementModels"
                    />
                  </Route>
                  <Route path="/section7">
                    <Section7 />
                  </Route>
                  <Route path="/email-form">
                    <EmailForm
                      {...{
                        setUserToken,
                        userToken,
                        LOCAL_STORAGE_KEY,
                        // setters
                        setDevelopers,
                        setSpecialists,
                        setSkills,
                        setOptions,
                        setIndustries,
                        setManagementPractices,
                        setTimelines,
                        setCompanySizes,
                        setPastExperience,
                        setProgressState,
                      }}
                    />
                  </Route>
                  <Route path="/call-form">
                    <CallForm
                      {...{
                        userToken,
                        setDevelopers,
                        LOCAL_STORAGE_KEY,
                        // setters
                        setUserToken,
                        setDevelopers,
                        setSpecialists,
                        setSkills,
                        setOptions,
                        setIndustries,
                        setManagementPractices,
                        setTimelines,
                        setCompanySizes,
                        setPastExperience,
                        setProgressState,
                      }}
                    />
                  </Route>
                  <Route
                    path="/redirect-success"
                    render={() =>
                      (window.location.href =
                        "https://www.maytechweb.com.au/success-calculator")
                    }
                  />
                  <Route
                    path="/redirect-landing"
                    render={() =>
                      (window.location.href =
                        "https://www.maytechweb.com.au/solutions/staff-augmentation/cost-calculator/")
                    }
                  />
                  <Route path="/">
                    <Section1
                      {...{
                        developers,
                        setDevelopers,
                        skills,
                        setSkills,
                        toggleSelectSkill,
                      }}
                    />
                  </Route>
                </Switch>
              </div>

              {/* Navigation */}
              <Navigation
                {...{
                  userToken,
                  developers,
                  specialists,
                  selectedTimeline,
                  selectedCompanySize,
                  selectedIndustry,
                  pastExperience,
                  setProgressState,
                  options,
                  skills,
                  managementPractices,
                  setShowPastExperienceCard,
                }}
              />
              {/* End Navigation */}
            </div>
          </div>
          {/* End left column */}

          {/* Right column */}
          <div className="col-lg-5 mt-4 mt-lg-0" id="progress-display-wrapper">
            <ProgressBar now={progressState} />
            <ProgressDisplay
              {...{
                developers,
                skills,
                specialists,
                selectedIndustry,
                options,
                managementPractices,
                selectedTimeline,
                selectedCompanySize,
                pastExperience,
                showPastExperienceCard,
              }}
            />
          </div>
          {/* End Right column */}
        </div>
      </div>
    </Router>
  )
}

export default App
